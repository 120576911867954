.brend-history{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #e3e4e5;
    padding: 70px;
    overflow-y: scroll;
}

#section00{
    position: relative;
}

.history-info{
    font-family: @font-light;
    color: #000;
    z-index: 1;
    position: relative;

    &__main{
        font-size: 36px;
        line-height: 43px;
    }

    &__else{
        font-size: 21px;
        line-height: 25px;
        margin-top: 35px;
    }
}

.autograph{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 330px;
    height: 156px;
    z-index: 0;
}

@media screen and (max-width:@screen-sm-min) {
    .brend-history{
        padding: 15px;
        padding-bottom: 70px;
    }

    .history-info{
        &__main{
            font-size: 24px;
            line-height: 32px;
        }
        &__else{
            font-size: 21px;
            line-height: 25px;
            margin-top: 35px;
        }
    }
}
