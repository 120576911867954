.plugin-blocks-element{
    flex-basis: calc(~"100%/3 - 6px");
    margin-bottom: 10px;
    position: relative;
    min-height: 447px;
    overflow: hidden;
    display: inline-block;

    .ipad-less({
        flex-basis: calc(~"50% - 10px");
        min-height: 350px;
    });

    .phone({
        flex-basis: 100%;
        min-height: 250px;
    });

    &__img{
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        z-index: 1;

        img{
            min-width: 100%;

            .phone({
                width: 100%;
            });
        }
    }

    &__info{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 100%;
        padding: 20px 45px;

        p{
            color: #fff;
            font-size: 40px;
            font-weight: 300;
            line-height: 43px;
            text-align: center;

            .ipad-less({
                font-size: 24px;
                line-height: 1.2;
            });
        }
    }

    &__title{
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        line-height: 17px;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        padding: 10px;

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 60px;
            height: 1px;
            background-color: #fff;
            opacity: 1;
        }
    }

    &_border{

        &:after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(~"100% - 40px");
            height: calc(~"100% - 40px");
            border: 1px solid #fff;
            z-index: 1;
        }
    }

    &_without-img{
        border: 1px solid #e8e8e8;

        .plugin-blocks-element__info{
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            display: inline-block;
        }
        .plugin-blocks-element__title,
        .plugin-blocks-element__info p{
            color: #000;
        }

        .plugin-blocks-element__title{

            &:after{
                background-color: #000;
            }
        }
    }
}

.cms-plugin .plugin-blocks-element_without-img{
    width: calc(~"100%/3 - 23.333333px");
}
