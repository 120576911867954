.btn-payment {
    @media(min-width: @screen-md){
        margin-left: 20px;
    }
}

body.order-page{

    .catalog{

        &__main-content{
            margin-bottom: 40px;
        }

        &-item-page{
            padding-bottom: 0;
        }
    }
}

label[for="id_city"]{
    margin-top: 20px;

    @media(min-width: @screen-md){
        margin-top: 0;
    }
}

.form-control{
    height: 40px;
}