.login-page{
    background-color: #fff;

    .ipad-less({
        .form-group{
       margin-bottom: 5px;
    }
    })
}

.email-confirm-page{

    .container-inner{
        text-align: center;
    }
}

.forms__list{
     @media(min-width: 1024px){
        position: relative;
        // left: -16%;
    }
}


.reset-form{
    text-align: center;

    label{
        text-align: left;
    }

    .form-group{
        width: 100%;
    }
}

.close{
    width: 40px !important;
    height: 40px !important;

    svg{
        width: 100% !important;
        height: 100% !important;
    }
}
.signup-form,
.login-form{
    width: 80% !important;
    display: block;
    margin: 50px auto;
    text-align: center;
    margin-bottom: 0;

    .ipad-less({
       margin-top: 30px;
       margin-bottom: 0;
    });

    label{
        text-align: left !important;
    }
    input{
        border: 1px solid #eee;
        box-shadow: none !important;
        transition: .65 ease-in-out;
        font-size: 1.5rem !important;
        color: #000 !important;
    }
}

.btn-inline{
    width: auto;
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
}

input[type="checkbox"]{
    appearance: checkbox;
}

.catalog-item-page label.term{
    line-height: 1.5;
    text-transform: none;
    margin-bottom: 25px;

    input{
        margin-bottom: 0px;
    }
}


.profile-orders-table{
    font-weight: 300;

    th{
        font-weight: 300;
    }
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea{
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    padding: 3px !important;
    padding-left: 8px !important;
}

input[type="submit"]{
    font-size: 1.5rem !important;

    @media(min-width: @screen-md){
        font-size: 1.8rem !important;
    }
}

