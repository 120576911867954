section.zoom {

  & img:hover {
      @media(min-width: 960px){
        opacity: 0 !important;
        cursor: zoom-in;
    }
  }
  img {
    transition: opacity .5s;
  }
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
}