.breadcrumbs-wrap{
    position: relative;
}

.breadcrumb {
    padding: 8px 0px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #FFF;
    border-radius: 4px;
}

.breadcrumbs {
    position: absolute;
    left: 50%;
    top: 60px;
    transform: translateX(-50%);
    width: 100vw;
    background-image: linear-gradient(180deg, #f0eff4 0%, #eae9ee 100%);
    height: 80px;
    display: flex;
    align-items: center;
    z-index: 1;

    .breadcrumb {
        text-transform: uppercase;
        margin-bottom: 0;
        background-color: transparent;
        padding-left: 0;

        &__link{
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 2px;
            color: #000;
            position: relative;
            padding: 10px 0;

            &:after{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                background-color: #b1b1b1;
                width: 60px;
            }
        }

    img{
        height: 22px;
    }

    li{
        font-size: 18px;
        line-height: 18px;
        text-transform: uppercase;
        font-family: @font-light;
        color: #000;
    }

    li + li:before{
        margin: 0 10px;
        color: #000;
        font-size: 18px;
        line-height: 18px;
    }

    li.active{
        color: #7b7b7b;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: none;
    }
  }
}
