.ofw_auto {
    overflow: auto !important;
}
.blog_back {
    background: #e3e4e5;
}
.wrp-blog {
    padding: 70px;
    @media screen and (max-width:768px) {
        padding: 20px;
    }
    p {
        font-family: @font-light;
        font-size: 21px;
        line-height: 25px;
        // margin-top: 35px;
    }
    h1 {
        line-height: 43px;
        font-size: 36px;
        font-family: @font-light;
    }

}
.pagination {
    margin-top: 70px;
    a,span {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border:1px solid #323232;
        margin-right: 15px;
        color:#323232;
        line-height: 32px;
        font-size: 16px;
        text-align: center;
        &:hover {
            text-decoration: none;
            border-color:#323232;
            background-color:darken(#e3e4e5,5%)

        }
        &.active {
            border-color:#323232;
            background-color:darken(#e3e4e5,5%)
        }
        &.prev {
            vertical-align: top;
            border:none;
            font-size: 0;
            background: url(/static/img/pag-arrow-prev.svg) no-repeat center;
            &.disabled {
                opacity: 0.3;
            }
        }
        &.next {
            vertical-align: top;
            border:none;
            font-size: 0;
            background: url(/static/img/pag-arrow-next.svg) no-repeat center;
        }
    }
}
.blog-article-preview {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 30px;

    a {
        display: block;
    }
    img {
        max-width: 100%;
    }
    a.h3 {
        font-size: 13px;
        font-weight: 400;
        vertical-align: top;
        margin-top: 0;

    }
    .date {
        display: block;
        margin-top: 5px;
    }
    p {
        margin-top: 10px;
    }
    .img-wrp {
        // height: 180px;
        overflow: hidden;
        border:1px solid #e3e4e5;
        display: block;
        img {
            transition:all .9s ease-out;
            transform:none;
            width: 100%;
        }
    }
    &:hover {
        .img-wrp {
            img {
                transform:scale(1.2 );
            }
        }
    }
}

.blog-article {
    margin-top: 0px;

    a {
        display: block;
    }
    h1 {
        margin-top: 0;
    }
    img {
        max-width: 100%;
        max-height: 400px;
    }
    a.h3 {
        vertical-align: top;
        margin-top: 0;

    }
    .date {
        display: block;
        margin-top: 5px;
    }
    p {
        margin-top: 10px;
    }

}
h3, .h3{
    font-family: @font-playfair;
    font-weight: 600;
}
