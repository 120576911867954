.catalog-page-info{
    margin-bottom: 60px;

    p{
        color: #4d4d4d;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        font-weight: 300;
    }

    .thing-modal__link{
        display: inline-block;
    }
}

.catalog-page-listing{
    margin-top: 30px;
    margin-bottom: 60px;
}
