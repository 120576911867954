.page-closed .mobile-header{
    display: none !important;
  }

  .b-promo{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 75px;
    justify-content: space-between;
    //- background: url(/static/img/b-promo.jpg) no-repeat center top;
    background-size: cover;
    // font-family: "GillSans", sans-serif;
    text-align: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;

    @media(min-width: 769px){
        height: 100vh;
        min-height: 800px;
    }

    &-shop{
        margin-bottom: 50px;
    }
  }

  .b-promo-content{
    color: #000;
  }

  .b-promo-collection{
    margin: 0 auto;
    font-size: 2rem;
    line-height: 1;
    font-weight: 100;
    color: #000;
  }

  .b-promo-title{
    font-size: 93px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .b-promo-description{
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
    color: #000;
  }

  .b-promo-form{
    margin-bottom: 20px;
  }
  .b-promo-form__send{
    background-color: #666;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: .5s ease-in-out;
  }

  .b-promo-form__send:hover{
    background-color: #000;
    transition: .5s ease-in-out;
  }

  .b-promo-form__text{
    color: #000;
    background: transparent;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .b-promo-form__text:focus{
    border-color: #666;
    color: #000;
  }

  .b-promo__list{
    display: flex;
    justify-content: space-between;
  }

  .b-promo-form__send,
  .b-promo-form__text{
    width: 315px;
    height: 65px;
    text-align: right;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 23px;
    margin-right: 15px;
    outline: none;
  }

  @media(max-width: 769px){
    .b-promo{
      background-position: top right;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      min-height: 100%;
      justify-content: space-between;
    }

    .b-promo-title{
      font-size: 26px;
    }

    .b-promo-description{
      font-size: 20px;
      line-height: 1.5;
      margin-top: 5px;

    }

    .b-promo-form__send,
    .b-promo-form__text{
      height: 50px;
      width: 95%;
      font-size: 100%;
    }
  }


.lookbook{
    .slide{
        height: 100vh;
        background-size: cover;
        overflow: hidden;
        background-position-x: center !important;
        margin: 5px 10px 5px 5px;
        display: flex;
        align-items: center;
    }
    h2{
        text-align: center;
        width: 100%;
    }
}
.lookbook-wrp:nth-of-type(odd) {
.slide{
        margin: 5px 5px 5px 10px;
    }
}
.p-0{
    padding: 0px !important;
}
