.social-buttons{
    z-index: 2;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    position: relative;

    &__item{
        display: inline-block;
        padding: 10px;
        border: 2px solid #fff;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: relative;
        margin: 10px;

        &_vk{
            background-color: #4c75a3;
        }
        &_fb{
            background-color: #3b5999;
        }
        &_instagram{
            background-color: #3f729b;
        }

        i{
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 18px;
        }
    }
}
