.item-card{
    display: flex;
    height: auto;
    width: 100%;

    .ipad-less({
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        padding-bottom: 40px;
    });

    .js-card-slick{


            &:hover:before{
                opacity: .15;
                transition: .435s opacity;
            }

            &:before{
                font-family: FontAwesome;
                content: "\f002";
                display: inline-block;
                position: absolute;
                z-index: 100;
                right: 30px;
                top: 25px;
                box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
                color: #353535;
                font-size: 2rem;
                transition: .235s opacity;
                opacity: 1;

                @media(max-width: 960px){
                    display: none;
                }
        }
    }

    &__fotorama{
        position: relative;
        padding-left: 10px;
        padding-right: 10px;

        @media(max-width: 960px){
            padding-left: 1px !important;
        }

        &--big{
            // max-width: 450px;
            min-width: 280px;
            width: 100%;
            height: auto !important;

            img{
                width: 100%;
                // max-width: 450px;
                min-width: 270px;
            }
        }

        img{
            width: 100%;
        }

        &-dots{
            position: absolute;
            bottom: 1.5rem;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;

            li{
                display: inline-block;
                font-size: 0 !important;
                margin-left: 5px;
                margin-right: 5px;

                &.slick-active{
                    button{
                        background-color: @brand-primary !important;
                    }
                }

                button{
                    width: 1rem !important;
                    height: 1rem !important;
                    background-color: #fff;
                    border: none;
                    border-radius: 50%;
                    padding: 0 !important;
                    position: relative;
                    padding: 1rem;

                    &:after{
                        border: 1px solid rgba(255,255,255,1);
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        border-radius: 50%;
                        filter: blur(.5px);
                    }
                }
            }
        }

        img{
            opacity: 0;
            border-top: 0px;
            transition: .235s opacity;
        }

        .ipad-less({
            width: 100% !important;
            min-width: 270px;
            border: 0;
        });
    }

    &__info{
        // width: 100%;
        padding-left: 37px;
        padding-bottom: 40px !important;
        align-self: center;

        .ipad-less({
            width: 100%;
            padding-left: 0;
        });
    }

    &__info-header{
        position: relative;
        padding-right: 30px;
        margin-bottom: 20px;

        i{
            position: absolute;
            top: 0;
            right: 0;
        }

        .like-btn i{
            // padding-top: 18px;
        }
    }

    &__catalog-name{
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        text-transform: none;
        font-size: 24px;
        font-weight: 300;
        font-family: @font-playfair;

        .ipad-less({
           padding-top: 15px;
        });
    }

    &__catalog-cost, &__catalog-crossed-out-cost{
        font-weight: 400;
    }

    &__catalog-crossed-out-cost{
        padding-right: 4px;
    }

    &__catalog-cost{

        &:after{
            font-family: FontAwesome;
            content: "\f158";
            font-size: 16px;
            margin-left: 5px;
        }
    }

    &__catalog-price{
        font-size: 23px;
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__catalog-price-size{
        color: #818181;
        font-size: 16px;
        font-weight: 300;
    }

    &__buy-btn{
        font-size: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding-top: 14px;
        padding-bottom: 14px;
    }

    &__catalog-info{
        font-size: 13px;
        font-weight: 300;
        line-height: 1.3;
        margin-bottom: 25px;

        ul{
            padding-left: 0;
        }
    }

    &__catalog-add-to-cart{
        border: 2px solid #131219;
        padding: 15px 20px;
        font-weight: 300;
        background-color: #131219;
        color: #fff;
        text-transform: uppercase;
        outline: none;
        transition: .235s ease-out;
        cursor: pointer;

        &:hover{
            background-color: transparent;
            color: #131219;
            transition: .235s ease-in;
        }

        .ipad-less({
            width: 100%;
        });

        &-register,
        &.js-enter,
        &#js-enter,
        &#js-register{
            min-width: 200px;
            max-width: 300px;
            display: block;
            box-sizing: border-box;
            margin: 0 auto;

            .ipad-less({
                border: 1px solid #000;
                max-width: 255px;
            });

            &:hover{
                background: #131219 !important;
                color: #fff !important;
            }
        }
    }
}

.like-btn{
    font-size: 20pt;
    color: @kbd-bg;

    &:hover {
        color: @brand-primary;
    }
}

.fotorama__fullscreen-icon{

    &:after{
        content: '\f002';
        font-family: FontAwesome;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        color: #fff;
    }
}

.fotorama__nav-wrap{

    .ipad-less({
        height: 80px;
    });
}

.fotorama__thumb{

    img{

        .ipad-less({
            position: absolute;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%);
        });
    }
}

.similar-products{
    padding: 50px 0;

    &__title{
        margin-bottom: 60px;
    }
}

.card-slick-small{
   overflow: hidden;
//    width: 450px;
   border-top: 8px solid #fff;
   margin-bottom: 20px;

   &__fotorama{
       padding-left: 0 !important;
       padding-right: 0 !important;
   }

   .slick-slide {
        margin: 0 21px;
    }

    .slick-list {
        margin: 0 -20px;
    }
}
