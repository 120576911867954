// variables
@static-path: "/static";
@font-base:  'Jost', sans-serif;;
@font-base-mt:  'Jost', sans-serif;;
@font-light:  'Jost', sans-serif;;
@font-bold:  'Jost', sans-serif;;
@font-bold-secondary:  'Jost', sans-serif;;
// @font-comfort:  "LucidaGrande", sans-serif;
// @font-comfort: 'Playfair Display', serif;
// @font-playfair: 'Playfair Display', serif;
@font-comfort: 'Jost', sans-serif;;
@font-playfair: 'Jost', sans-serif;;

@font-size-h1:            floor((@font-size-base * 2.6)); // ~36px
@font-size-h2:            floor((@font-size-base * 2.15)); // ~30px
@font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

@headings-font-weight:    300;

// fonts
// @import "fonts/gillsans";
// @import "fonts/opensans";
// @import "fonts/lucida";
// @import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;300;500&display=swap');

// old
// @import (less) "../css/theme/style.css";

@import "bootstrap";
@import "seasonsstore/mixins";
// @import (less) "../css/theme/plugin/jPushMenu.css";
// @import (less) "../css/theme/plugin/animate.css";
@import (less) "../css/theme/jquery-ui.css";
// @import (less) "../css/theme.css";
@import (less) "../css/theme/plugin/owl.carousel.css";
@import (less) "../css/theme/plugin/selectric.css";
@import (less) "../css/jquery.kladr.min.css";
// @import (less) "../css/theme/plugin/owl.theme.default.min.css";
// @import (less) "../css/theme/plugin/flexslider.css";
// @import (less) "../css/theme/navigation.css";
// @import (less) "../css/theme/plugin/mediaelementplayer.css";
// @import (less) "../css/theme/plugin/colorbox.css";
// @import (less) "../css/theme/plugin/jquery.fs.tipper.css";
@import (less) "../js/fotorama/fotorama.css";
@import (less) "../css/google-translate.css";
// @import (less) "vendor/video-js.css";

@font-size-h1:            floor((@font-size-base * 2.6)); // ~36px
@font-size-h2:            28px;
@font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

@headings-font-weight:    300;

// APPS PATH
@half-1: "../../../apps/";
@half-2: "/static/styles/app-main";

// APPS FILES
@import "@{half-1}catalog@{half-2}";
@import "@{half-1}blocks@{half-2}";
@import "@{half-1}cmsplugin_slide@{half-2}";

// blocks
// @import "font-awesome/font-awesome";
@import "blocks/block-base";
@import "blocks/block-breadcrumbs";
@import "blocks/block-promo";
@import "blocks/core";
@import "blocks/block-footer";
@import "blocks/block-subscription";
@import "blocks/catalog-btn";
@import "seasonsstore/side-nav";
@import "seasonsstore/social-buttons";
@import (less) "../css/slideout.css";
@import "seasonsstore/core";
@import "seasonsstore/index";
@import "seasonsstore/brend-history";
@import "seasonsstore/look-book";
@import "seasonsstore/contacts";
@import "seasonsstore/catalog";
@import "seasonsstore/catalog-card";
@import "seasonsstore/item-card";
@import "seasonsstore/catalog-item-page";
@import "seasonsstore/blog";
@import "seasonsstore/catalog-page";
@import "seasonsstore/cart";
@import "seasonsstore/order";
@import "seasonsstore/login-page";
@import "seasonsstore/map";
@import "seasonsstore/header";
@import "seasonsstore/catalog-list";
@import "seasonsstore/slick";
@import "seasonsstore/img-zoom";

@fa-font-path: "/static/fonts/font-awesome/";
@grid-float-breakpoint: 1024px;
@grid-float-breakpoint-max: 1023px;

.whats-app-call {
    display: block;
    position: fixed;
    bottom: 30px;
    right: 30px;
    bottom:100px;
    z-index:100;
    width: 50px;
    height: 50px;
    background-color: #40ad13;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    box-shadow: 0px 0px 10px #0005;
}
.whats-app-call i{
    color: #FFF;
    font-size: 32px;
    line-height: 50px;
}

.whats-app-call .whats-app-title {
    display: none;
    color: #000;
    line-height: 48px;
    padding-right: 10px;
}

.whats-app-call a:hover .whats-app-title,
.whats-app-call a:focus .whats-app-title{
    display: block;
    position: absolute;
    width: 200px;
    float: left;
    right: 25px;
}

@media screen and (min-width: 768px) {
    .whats-app-call {
        bottom: 109px;
    }
}
@media(max-width: 780px){
    .whats-app-call {
        bottom:100px;
        z-index:100;
    }
}
.dropdown-menu{
    min-width: 50px;
}
body{
    top: 0px !important;
}
body.delivery-page{
    .main-title{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .catalog-item-page .container{
        // background-color: #F4F4F4;
    }
}
.header-container{
    background-color: #FFF;
}
