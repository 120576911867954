.contacts{
    position: fixed;
    width: 100%;
    height: 100%;

    iframe{
        margin-top: -150px;
        height: calc(~"100% + 150px");
    }

    &__info{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1;
        padding: 70px;
        display: -webkit-flex;
        display: -webkit-box;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__phone,
    &__email,
    &__address{
        width: 90%;
        border-bottom: 1px solid #fff;

        a{
            font-size: 80px;
            line-height: 158px;
            color: #fff;
            text-transform: uppercase;
        }
    }

    &__logo{
        position: absolute;
        top: 70px;
        left: 70px;
        width: 280px;
        z-index: 2;

        @media screen and (max-width: @screen-sm-min){
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

@media screen and (max-width: @screen-lg-min) {
    .contacts{
        &__phone,
        &__email,
        &__address{

            a{
                font-size: 40px;
                line-height: 80px;
                color: #fff;
            }
        }
    }
}

@media screen and (max-width:@screen-md-min) {
    .contacts{
        &__phone,
        &__email,
        &__address{

            a{
                font-size: 40px;
                line-height: 80px;
                color: #fff;
            }
        }
    }
}

@media screen and (max-width:@screen-sm-min) {
    .contacts{
        &__info{
            padding-left: 30px;
            margin: 0 auto;
        }

        &__phone,
        &__email,
        &__address{


            a{
                font-size: 18px;
                line-height: 48px;
                color: #fff;
            }
        }
    }
}
