.slider-item{
    max-height: 580px;
    height: 580px;
}

.main-slider{
    max-width: 1170px;
    margin: 0 auto;

    .item{
        height: 580px;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }
}

.block-carousel-header-img{
    max-height: 600px;
}
.main-slider{
    &--dark{
        .main-slider{
            &__title{
                color: #FFF;

                &:after{
                    background-color: #FFF;
                }
            }
            &__content p{
                color: #FFF;
            }
            &__button{
                border: 1px solid #FFF;
                color: #FFF;
            }
        }
    }
    &--light{
        .main-slider{
            &__title{
                color: #000;

                &:after{
                    background-color: #000;
                }
            }
            &__content p{
                color: #333;
            }
            &__button{
                border: 1px solid #323232;
                color: #000;
            }
        }
    }
}

.main-slider{

    &__item{
        padding-top: 70px;
        padding-bottom: 50px;
        position: relative;

        picture {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }

        img {
            width: 100%;
        }
    }

    &__title{
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        line-height: 17px;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        padding: 10px 0;
        margin-bottom: 30px;

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0px;
            width: 60px;
            height: 1px;
            opacity: 1;
        }
    }

    &__subtitle{
        font-size: 48px;
        font-weight: 300;
        line-height: 54px;
        font-weight: 300;
        margin-bottom: 25px;
    }

    &__description{
        color: #4d4d4d;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    &__button{
        padding: 10px 45px;
        text-transform: uppercase;
        letter-spacing: 3px;
        border-radius: 2px;
    }

    &__inner{
        &:extend(.container);
        position: relative;
        height: 100%;
    }

    &__content {
        max-width: 50%;
        margin-left: 90px;
        margin-top: 40px;
    }

    &__dots{
        position: absolute;
        bottom: 70px;
        left: 115px;
        list-style: none;
        display: flex !important;
        padding-left: 0;

        li{
            margin-right: 10px;

            button{
                font-size: 0;
                border-radius: 50%;
                width: 7px !important;
                height: 7px !important;
                border: none;
                box-shadow: none;
                padding: 0;
            }
        }
    }
}

li.slick-active button{
    background-color: #D64B4B !important;

    &:active,
    &:focus{
        outline: none;
    }
}
