.catalog-item-page{

    &__main-content{
        padding-top: 0px;
        position: relative;

        // .ipad-less({
        //     padding-top: 30px;
        // });

        // .index-page &{
        //     display: none;
        // }
    }

    .ipad-less({
        border: none;
        // padding-bottom: 40px;

        ul{
            list-style: none;
            padding-left: 0;
        }
    });

    label{
        font-size: 12px;
        display: inline-block;
        color: #131219;
        margin: 0 0 8px;
        line-height: 12px;
        font-weight: 300;
        text-transform: uppercase;
    }

    input,
    select,
    textarea{
        border: 1px solid #eee;
        padding: 16px 18px 14px;
        margin-bottom: 24px;
        color: #8f8f8f;
        font-size: 11px;
        font-weight: 300;
        background: #fff;
        max-width: 100%;
        outline: 0;
        font-family: inherit;
        border-radius: 0;
        box-shadow: none;
    }

    .form-control{
        height: 40px;

        &:focus{
            box-shadow: 0 0 8px rgba(0,0,0, 0.6);
        }
    }

    button{
        // width: auto;
        padding-left: 30px;
        padding-right: 30px;
    }

}

.remove-basket{
    font-size: 22px;
    color: #000;
    transition: .235s ease-out;

    &:hover{
        opacity: .8;
        transition: .235s ease-in;
    }
    .fa{
        font-size: 20px;
        color: #000;
    }
}

.checkout-review-order{
    padding: 50px;
    background-color: #fafafa;

    @media(max-width: 960px){
        padding: 15px !important;
        margin-left: -15px;
        margin-right: -15px;
    }
}

.cart-table {
  border: 0;
  width: 100%;
  border-spacing: 0;

  td{
    vertical-align: middle;
    font-weight: 300;
    font-size: 13px;
  }
}
.table.cart-table > tbody > tr > td{
    vertical-align: middle;
}

.cart-table thead {
  padding-bottom: 10px;
}
.cart-table thead tr th {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  padding: 0 0 10px 0;
  color: #131219;
  line-height: 1;
  text-transform: uppercase;
  border-bottom: 1px solid #f8f8f8;
  vertical-align: middle;
}

.promo-code {
  input {
    width: 160px;
    margin-right: 10px;
    text-transform: uppercase !important;

    @media(min-width: @screen-md){
        width: 200px;
    }

    &::-webkit-input-placeholder {
        text-transform: none !important;
    }
  }

  &__btn{
      .btn__bordered();

      height: 40px;
      padding: 0 15px;
  }
}

.btn:hover, .button:hover, input[type=submit]:hover {
    background: #131219;
    border-color: #131219;
    color: #fff !important;
}
.catalog-item-page input.add-to-cart_normalize{
    border: 2px solid #131219;
    padding: 18px 50px 17px;
    font-weight: 500;
    color: #131219;
    text-transform: uppercase;

//     &:hover {
//     background: #131219;
//     border-color: #131219;
//     color: #fff;
// }
}

.btn__bordered{
    border: 2px solid #131219;
    padding: 18px 50px 15px;
    font-weight: 500;
    color: #131219;
    text-transform: uppercase;
    display: block;
}

*{
    outline: none !important;
}

#id_email{
    width: 100%;
}
input, textarea{
    font-size: 16px;
    border: 2px solid #131219;
    padding: 15px 20px;
    font-weight: 300;
    background-color: #131219;
    color: #fff;
    text-transform: uppercase;
    outline: none;
    -webkit-transition: 0.235s ease-out;
    -o-transition: 0.235s ease-out;
    transition: 0.235s ease-out;
    text-transform: none !important;
}

.close{
    background-color: transparent !important;

    svg{
        width: 100%;
        height: 100%;
        max-width: 100%;
    }
}

.alert-error {
    .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);
}

.input-replacement {
    cursor: pointer;
    border: 0;
}
