.catalog-list{
    width: 100%;
    max-width: 256px;
    padding: 0;
    margin-bottom: 45px;
    position: relative;
    z-index: 2;
    margin-top: -8px;
    font-family: @font-playfair;

    .index-page &{
        padding: 10px 30px 0 30px;
        position: absolute;
        top: 0;
        border: 1px solid transparent;

        &.open {
            border: 1px solid #f5f5f5;
        }
    }

    &__title{
        margin-top: 0;

        button {
            font-size: 14px;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: .2em;
            border: none;
            box-shadow: none;
            background-color: transparent;
            position: relative;
            padding-left: 0;

            &:after {
                content: '';
                position: absolute;
                bottom: -5px;
                left: 0px;
                width: 0;
                height: 1px;
                background-color: #b1b1b1;
                transition: width .25s;
            }

            &:hover {

                &:after {
                    width: calc(~"100% - 9px");
                    transition: width .25s;
                }
            }

            &:focus {
                outline: none;
            }
        }
    }

    &.open .category-list {
        display: block;
    }

    .category-list{
        display: none;
        list-style: none;
        padding-left: 0;

        &__sidebar-link,
        a{
            display: inline-block;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            padding: 10px 0;
            color: #000;
            width: 100%;

            &:hover{
                .active-border();
            }
        }
        li.selected{
            a{
                .active-border();
            }
        }
    }

    a{
        list-style: none;
        text-align: right;
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        padding: 10px 0;
        color: #000;
        width: 100%;

        &:hover{
            .active-border();
        }

        &.active{
            .active-border();
        }
    }

}
