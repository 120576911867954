.thing-modal.in{
    display: flex !important;

    .ipad-less({
        flex-direction: column;
    });
}

.modal-open{
    overflow: hidden !important;
}

.modal-item-card{
    border: 10px solid #FFF;
    padding: 20px;
    margin: 0 auto;
    width: 980px;
    height: auto;
    background-color: #fff;
    position: relative;
    // overflow-y: auto;
    height: 100vh;

    @media screen and (min-height: 1000px) {
        height: auto;
    }

    .ipad-less({
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        overflow-y: auto;
        margin-top: 0;
    });

    .close{
        position: absolute;
        top: -8px;
        right: -70px;
        width: 40px;
        height: 40px;
        background-color: #FFF;
        opacity: 1;
        color: #6c6c6c;
        font-size: 32px;
        z-index: 1051;

        &:hover{
            color: #000;
        }

        .ipad-less({
            right: -50px !important;
        });
    }
}

.thing-modal{
    bottom: auto;

    @media screen and (min-height: 1000px) {
        margin-top: 10%;
    }

    @media screen and (max-width: 767px) {
        min-height: 100vh;
    }

    &__photo{
        width: 50%;

        .ipad-less({
            width: auto;
        });

        img{
            width: 100%;

            .ipad-less({
                max-height: 300px;
                width: auto;
                margin: 0 auto;
            });
        }
    }

    &__body{
        width: 50%;
        position: relative;
        overflow: hidden;

        .ipad-less({
            width: 100%;
            position: static;
            overflow: inherit;
        });
    }

    &__body-inner{
        padding: 0 40px;
        padding-bottom: 40px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        top: 60px;
        left: 0;
        height: calc(~"100% - 60px");
        width: 100%;

        .ipad-less({
            width: 100%;
            height: auto;
            overflow: auto;
            overflow-y: auto;
            position: static;
        });

        ul{
            padding-left: 0;
        }
    }

    &__body-close{
        padding: 0 40px;
        height: 60px;
        position: relative;

        .close{
            position: absolute;
            right: 40px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 36px;
            opacity: 1;

            span{
                color: #000;
            }
        }
    }

    &__title{
        color: #373738;
        font-size: 28px;
        font-weight: 400;
        line-height: 38px;
        letter-spacing: 1px !important;
        text-transform: none;
        font-weight: 300;
        margin-top: 0;
    }

    &__price{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 40px;

        .price{
            color: #202020;
            font-size: 23px;
            font-weight: 400;
            line-height: 26px;
        }

        .size{
            color: #818181;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
        }
    }

    &__modal-btn{
        background-color: transparent;
        border: 1px solid #000;
        border-radius: 2px;
        width: 100%;
        margin-bottom: 5px;

        &:hover{
            background-color: #131219;
            color: #FFF;
        }
    }

    &__description{
        color: #585858;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
    }

    &__link{
        color: #585858;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        padding-bottom: 5px;
        border-bottom: 1px dashed #585858;

        &:hover{
            text-decoration: none;
            color: #585858;
            border-bottom: 1px solid #585858;
        }
    }
}
