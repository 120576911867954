.prefooter{
    background-color: #272727;
}

.block-subscription{
     @media(max-width: 980px){
       padding: 15px 0;
       margin-top: 0;
    }
    input{
        color: #fff;
    }
}

.block-subscription-inner {

  height: 100px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;

  p.agreement {
    color: #fff;
  }

  @media(min-width: 960px){
      padding: 30px;
  }

  .ipad-less({
      flex-direction: column;
      padding: 0;
    });

   @media(max-width: 980px){
        height: 100%;
        -webkit-align-items: left;
        -ms-flex-align: left;
        align-items: left;
    }
}

.subscription-title,
.block-subscription-form{
    min-width: 30%;
}

.subscription-title{
    color: #fff;
}

.block-subscription-pre-title{
    color: darken(#fff, 40%);
}

.block-subscription-title{
    color: #fff;
    text-transform: none;
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 400;

    @media(max-width: 980px){
        font-size: 16px;
        margin-bottom: 20px;
    }
}

.block-subscription-form {
  margin-bottom: 30px;

  .form-control {
    border: 0;
    background: #f7f7f7;
    color: #eee;
    font-size: 14px;
    .box-shadow(none);
    font-style: italic;

    &:focus {
      .box-shadow(none);
      font-style: normal;
    }
  }

  .input-group-btn {
    &:last-child {
      > .btn,
      > .btn-group {
        margin-left: 0;

        &:hover,
        &:focus,
        &:active {
        //   z-index: 0;
        }
      }
    }
  }
}

.block-subscription-form{
    max-width: 320px;
    margin-bottom: 0 !important;

    .ipad-less({
        max-width: none;
        width: 100%;
    });

    .input-group{
        display: inline-block;
        width: 100%;
    }

    .form-control{
        width: 100%;
        position: relative;
        background-color: #3d3d3d;
        padding: 25px 15px;
        height: 64px;
    }

    .input-group-btn{
        width: 64px;
        position: absolute;
        display: inline-block;
        right: 0px;
        background-color: #505050;
        z-index: 10;

        .btn{
            width: 64px;
            height: 64px;
            background-color: #505050;
            border: none;
            z-index: 1;

            span{
                font-size: 36px;
            }
        }
    }
}


@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 35%;
  }
}

.modal-dialog {
  vertical-align: middle;
}

.subscription-modal{
    .modal-content{
        width: 370px;

        .modal-body{
            padding: 25px 35px;
        }
    }
    h4{
        margin-bottom: 20px;
    }
    .form-control {
        height: 35px;
    }
}
