.footer{
    background-color: #000;
    // padding: 40px 0;
    // position: absolute;
    width: 100%;
    // bottom: -500px;

    @media(max-width: 767px){
        // bottom: -660px !important;
    }

    &-inner{
        padding: 45px 5px 20px;
    }

     .ipad-less({
        //  padding: 30px 0;
     });

    .logo{
        margin-bottom: 35px;
        display: inline-block;
    }

    &__address{
        color: #fff;
        margin-bottom: 35px;

        p{
            margin-bottom: 0;
            color: #d9d9d9;
            font-weight: 400;
        }
        a{
            color: #FFF;
        }
    }

    &__cards{
        display: flex;
        color: #cfcfcf;
        margin-bottom: 35px;

        i{
            display: inline-block;
            margin-right: 25px;
            font-size: 24px;
        }
    }

    &__copy{
        color: #d9d9d9;
    }
    &-wm{
        padding-top: 10px;
        padding-right: 20px;
    }

    &__socials{
        list-style: none;
        padding-left: 0;
        text-align: right;
        margin-top: -15px;


        .ipad-less({
               margin-left: -15px;

            });

        li{
            display: inline-block;
        }

        a{
            color: #fff;
            padding: 0 20px;

            .ipad-less({
                padding: 20px;
                padding-top: 0;
                padding-bottom: 10px;
                padding-right: 0;
            });
        }

         .ipad-less({
             margin-left: 10px;
             text-align: right;
         });
    }
}

.footer-menu-title{
    color: #fff;

     .ipad-less({
         margin-right: -15px;
    });
}

.footer-menu{
    list-style: none;
    padding-left: 0;

    .ipad-less({
         margin-right: -15px;
    });

    a{
        color: #9b9b9b;
    }

    &__link{
        color: #fff;
    }

    &__list{
        text-align: right;
        .ipad-less({
            margin-top: 5px;
            text-align: right;
        });
    }
}
