html.slideout-open .slideout-menu{
    background-color: #1b1b1b;
}

.mobile-menu{

    ul{
        list-style: none;
        padding-left: 0;
    }

    &__list{
        display: none;

        .slideout-open &{
            display: block;
        }

        a{
            display: block;
            color: #d8d8d8;
            padding: 13px 30px;
            font-weight: 300;

            &:hover{
                color: #fff;
            }
        }
    }

    .has-child{
        position: relative;

        &:before{
            content: '\f0d8';
            font-family: FontAwesome;
            font-size: 22px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .collapsed.has-child{

        &:before{
            content: '\f0d7';
        }
    }
}

.mobile-child-nav{
    padding-left: 20px;
    background-color: #000;
}
