.plugin-blocks{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 32px;
}

img{
    width: 100%;
    max-width: 100%;
    height: auto;
}

a[href^=tel] {
  color: inherit !important;
}