.look-book{
    &__title{
        font-size: 120px;
        letter-spacing: 8px;
        font-family: @font-bold-secondary;
        line-height: 100%;
        color: rgba(255, 255, 255, 0.5) !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__section-img{
        height: 100%;
        max-width: none;
    }
}

.test-area{
    display: inline-block;
    position: relative;

    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        background-color: #fff;
    }
}

.look-book-arr{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    height: 560px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.5) !important;

    &:after,
    &:before{
        content: '';
        position: absolute;
    }

    &:after{
        bottom: 0;
        left: 0;
        width: 90px;
        height: 90px;
        border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
        transform: rotate(-45deg) translate(-21px, -42px);
    }

    &:before{
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
        width: 128px;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.5) !important;
    }
}

.look-book__container{
    width: auto;
    margin: 0 auto;
    position: relative;
    max-width: 1700px;
    box-shadow: inset 30px 0 30px #000;
    text-align: center;
}

.section1__img{
    height: 100%;
    width: 100%;
}

.show-me-dot{
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 50%;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 1;

    &:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: rgba(255,255,255,0.3);
    }

    &_hidden{
        visibility: hidden;
    }
}

.shop-info{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 187px;
    height: 187px;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &_active{
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        visibility: visible;
        z-index: 3 !important;
        background-color: rgba(0,0,0,0.4);
    }
}

.item-name{
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 20px;
    text-transform: none;
    font-weight: normal;
    position: relative;

    &:before{
        content: '';
        position: absolute;
        width: 63px;
        height: 1px;
        background-color: #fff;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.item-price{
    font-size: 25px;
    line-height: 30px;
    font-family: @font-light;
}

#fp-nav{
    left: 15px;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span{
    margin: -4px 0 0 -4px;
    border: 1px solid #fff;
    height: 3px;
    width: 3px
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span{
    height: 12px;
    width: 12px;
    margin: -7px 0 0 -9px;
    border-radius: 100%;
}

@media screen and (max-width:@screen-sm-min) {
    .look-book__title{
        position: absolute;
        bottom: 15px;
        left: 15px;
        top: inherit;
        transform: translate(0, 0);
        font-size: 26px;
        letter-spacing: normal;
    }
}

@media screen and (max-width: 768px) {
    #fp-nav{
        display: none;
    }

    .show-me-dot{
        display: none !important;
    }

    .look-book-arr{
        height: 250px;
    }
}

@media (min-width: 421px) and (max-width: 767px) {

    .show-me-dot{
        display: none !important;
    }

    .look-book__container{
        height: 100%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        text-align: center;

        img.look-book-img{
            height: 100vh !important;
        }
    }

    .look-book__title{
        position: absolute;
        bottom: 15px;
        left: 15px;
        top: initial;
        transform: translate(0, 0);
        font-size: 26px;
        letter-spacing: normal;
    }
}
