.card-catalog{

    &__thing-name{
        display: inline-block;
        font-size: 12px;
        line-height: 15px;
        color: #232323;
        font-weight: 300;
        margin-bottom: 5px;
        margin-left: 10px;

        .ipad-less({
            font-size: 12px;
            line-height: 16px;
            color: #232323;
            margin-bottom: 5px;
            max-width: 135px;
            max-height: 16px;
            overflow: hidden;
        });
    }

    &__thing-crossed-out-cost{
        padding-right: 4px;
        .ipad-less({
            display: none;
        });
    }

    &__thing-price{
        font-size: 12px;
        line-height: 1;
        font-weight: 300;
        color: #232323;
        // margin-right: 10px;
        position: relative;
        right: 10px;
        margin-bottom: 5px;
        line-height: 16px;

        // span:after {
        //     font-family: FontAwesome;
        //     content: "\f158";
        //     position: absolute;
        //     right: -12px;
        //     font-size: 12px;
        // }
        .ipad-less({
            font-size: 12px;
        });
    }

    &__thing-description{
        transition: all 0.3s;
        // height: 50px;
        position: absolute;
        bottom: 0px;


        .ipad-less({
            // height: 80px;
            position: relative;
        });

        &:hover,
        &:focus,
        &:active{
            transition: all 0.3s;
            color: #fff;
            text-decoration: none;
            opacity: 1;

            .ipad-less({
                color: #000;
            });
        }
    }
}

.center-block.card-catalog-img{
    // max-height: 480px;
}
.card-slick-small{
    margin-top: 10px;
}
