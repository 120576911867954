.catalog-btn-radio {

    &.no {
      color: #ccc;
      pointer-events: none;
      cursor: auto;
      opacity: .8;
      background: #fff !important;
    }
  }
