.thing-info-link{
    margin-bottom: 30px;
}

.thing-info,
fieldset{

    h5.h5{
        font-size: 13px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 1.3;
        margin-bottom: 0px;
        margin-top: 0;
        text-transform: uppercase;
    }

    p{
        font-size: 13px;
        font-weight: 300;
        line-height: 1.3;
        margin-bottom: 20px;
    }
}

fieldset.thing-info-color{
    margin-bottom: 20px;

     label{
        display: inline-block;
        width: 30px;
        height: 40px;
    }

    img{
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

    .catalog-btn-img{
        border: 1px solid #eee;
        margin-right: 10px;
    }
}

.form-control{
    box-shadow: none !important;
}

input[type="text"],
select,
textarea{
    border: 1px solid #ddd !important;
}

.popular-items{
    .popular-items-list{
        // margin-top: 10px;
        // margin-bottom: 20px;
    }
}
.promo-main{
    display: block;
    // margin-bottom: 40px;
    @media (max-width: 1024px) {
        // margin-bottom: 15px;
    }
}
h3{
    @media (max-width: 1024px) {
        font-size: 16px;
        margin-top: 8px;
        margin-bottom: 8px;
    }
}
