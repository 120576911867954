.lookbook-modal{

    .modal-body{
        padding: 0;
        margin-top: 0;

        .ipad-less({
            padding: 5px;
        });
    }

    .modal-dialog{
        position: fixed;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0%) !important;
        margin: 0;
        min-width: 935px;
        padding-top: 60px;

        .ipad-less({
            width: 100%;
            min-width: 0;
            max-height: 100vh;
            top: 0;
            // transform: translateX(-50%);
            overflow-y: auto;
        });
    }

    .modal-content{
        background-color: transparent;
        box-shadow: none;
        border: none;
        border-radius: 0;
    }

    .modal-header{
        text-align: center;
        border-bottom: none;
    }

    button.close{
        color: #fff;
        font-size: 24px;
        outline: none;
        width: 40px;
        height: 40px;
        position: absolute;
        top: -25px;
        right: 10px;
    }

    .modal-title{
        color: #ffffff;
        font-size: 28px;
        font-weight: 400;
        line-height: 38px;
    }
}

.modal-backdrop{
    background-color: rgba(76, 76, 76, 0.95) !important;
    opacity: 1 !important;
}

.lookbook-modal-body{
    // display: flex;
    // justify-content: space-between;
    // align-items: stretch;

    .col-xs-6{
        padding-left: 3px;
        padding-right: 3px;
        margin-bottom: 6px;
    }

    .ipad-less({
        // flex-direction: column;
        // align-items: center;
        // justify-content: space-between;
    });

    &__item{
        // flex-basis: calc(~"50% - 12px");
        display: inline-block;
        vertical-align: top;

        .ipad-less({
            // flex-basis: 100%;
        });
    }

    &__img{
        width: 55%;
        margin-right: 2px;


        .ipad-less({
            height: 400px;
            margin: 0 auto;
            margin-bottom: 40px;
            text-align: center;

            .phone({
                height: auto;
                max-height: 400px;
                // width: 100%;
            });

            img{
                height: 100%;
                display: inline-block;
                // margin: 0 auto;
                // width: auto;

                .phone({
                    height: auto;
                    // width: 100%;
                });
            }
        });

        img{
           width: auto;
        }
    }

    &__things-list{
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        // align-content: space-between;
        // margin-bottom: -24px;
       width: 30%;
       margin-left: 10%;

        .ipad-less({
            width: 43%;
            margin-left: 0%;
        });
    }

    &__thing{
        // flex-basis: calc(~"50% - 12px");
        margin-bottom: 24px;

        .ipad-less({
            // flex-basis: calc(~"50% - 20px");

        });
    }
}

.thing-modal-card{
    position: relative;
    height: 300px;

    .ipad-less({
        margin-bottom: 40px;
        height: auto;
        display: inline-block;
    });

    &__img{

        .ipad-less({
        });

        img{
            width: 100%;

            .ipad-less({
                margin: 0 auto;
                width: auto;
            });
        }
    }

    &__title{
        // position: absolute;
        // bottom: 0;
        // left: 0;
        width: 100%;
        background-color: #fff;
        margin-bottom: 0;
        min-height: 20px;
        padding: 2px 20px;
        text-align: center;
        color: #151515;
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;

        span{
            padding-left: 10px;

            .ipad-less({
                display: block;
                position: relative;
                top: 2px;
            });
        }

        .ipad-less({
            // background-color: transparent;
            // color: #fff;
            position: static;
            padding: 2px 5px;
            font-size: 11px;
            line-height: 13px;
        });
    }
}
