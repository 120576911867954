.up-header{
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-top: 16px;
    margin-bottom: 16px;

    &__social-buttons{
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
    }

    &__logo-text{
        text-align: center;
        opacity: 0.42;
        font-size: 11px;
        margin-top: -5px;
        color: #949494;
    }

    &__logo{
        // margin-right: 30px;
        // width: 150px;
        margin-top: -2px;
        display: flex;
        align-items: center;
    }
    &__right{
        display: flex;
        align-items: center;
        margin-top: 0px;
        // flex-grow: 1;
        // justify-content: flex-end;
    }
    .navbar-header{
        margin-top: 7px;
    }
}

.login-menu{

    .mobile-menu &{
        padding-left: 10px;
        margin-top: 30px;

        i{
            display: none;
        }
    }

    i{
        font-size: 15px;
        margin-right: 10px;
    }

    a{
        display: inline-block;
        padding: 5px 10px;
        color: #000;

        .mobile-menu &{
            color: #fff;
            font-size: 16px;
        }
    }

    &__login-link{
        position: relative;
    }
}

.header-icons{

    &__item{
        display: inline-block;

        // @media(min-width: 960px){
        //     min-width: 60px;
        // }
    }

    &__count{
        display: inline-block;
        background-color: #999;
        color: #FFF;
        font-size: 9px;
        position: relative;
        top: -10px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        text-align: center;
        left: -8px;
    }

    a{
        display: inline-block;
        padding: 5px 5px;
        color: #000;
        font-size: 14px !important;

        @media(max-width: 960px){
            padding-left: 2px;
            padding-right: 0;
            padding-top: 8px;
        }
    }

}

.social-buttons__link{
    display: inline-block;
    color: #000;
    padding: 0 18px;
    padding-left: 0;
    font-size: 18px;
}

.login-menu{
    font-weight: 300;
}
