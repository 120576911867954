/* Прячем панель гугла */

.language-toggle{
    background: transparent !important;
    color: #FFF !important;
    margin-top: 3px;
}
.language-dropdown-menu{
    min-width: auto;
}
.language-dropdown-menu > li > a.language__img{
    padding: 3px 14px;
}
.language-dropdown-menu .language__img img{
    margin-right: 7px;
}


.skiptranslate {
    display: none !important;
}

/* language */

.language {
    position: fixed;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    display: flex;
    flex-direction: column;
}

.language__img {
    margin: 2px;
    cursor: pointer;
    opacity: .5;
}

.language__img:hover,
.language__img_active {
    opacity: 1;
}

/* content */

.content {
    text-align: center;
    margin: auto;
}
