.mobile-header{
    height: 48px;
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 10px;

    &__logo{
        display: inline-block;
        width: 150px;
        position: absolute;
        left: 50%;
        top: 18px;
        transform: translate(-50%, -50%);

        img{
            width: 100%;
        }
    }

    .toggle-button{
        font-size: 24px;
        height: 100%;
        background-color: transparent;
        background-image: none;
        border: none;
        box-shadow: none;
        padding: 0;
    }
}
