.sidebar-links{
    width: 100%;
    display: flex;
    flex-direction: column;

    &_horizontal{
        flex-direction: row;
        justify-content: center;

        .sidebar-link{
            width: calc(~"100%/3");
            max-width: 250px;
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 60px;
        }
    }
}

.sidebar-link{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 60px;
    padding-right: 30px;
    width: 100%;
    border: 1px dashed #979797;
    margin-bottom: 25px;
    height: 70px;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 4px;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;

    &:hover{
        text-decoration: none;
        border: 1px dashed #000;
    }

    &__icon{
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 32px;
        color: darken(#fff, 50%);
    }

    &__text{
        display: inline-block;
        flex-shrink: 2;
        text-align: center;
    }
}
