.navbar.navbar-collapse.collapse{

    @media (min-width: 1024px) {
        display: flex !important;
        justify-content: center;
    }

    @media screen and (max-width: 1023px) {
        display: none;

        .in&{
            display: block;
        }
    }
}

a{

    &:hover{
        color: inherit;
        text-decoration: none;
    }
}

.navbar{
    padding: 0 !important;
}

.navbar-collapse{
    width: 100%;
    background-color: transparent;
}

.nav.navbar-nav{

    .ipad-less({
        width: 100%;
        padding: 0 20px;
    });
}

.navbar-default{
    // font-family: @font-playfair;
    background-color: transparent;
    float: none;
}

.navbar-default .navbar-toggle{
    border-color: transparent;
}

.navbar-default .navbar-toggle .icon-bar{
    background-color: #000;
}

.navbar-toggle{
    float: left;
}

.navbar-nav{

    .ipad-less({
        margin-left: 0;
    });
}

.navbar{
    margin-bottom: 0;
    border: none;
    padding-top: 20px;
    min-height: auto;

    li:first-child{

        a{
            color: red !important;
            // padding-left: 0;
        }
    }

    li a{
        padding: 0px 10px 0px;
        // text-transform: uppercase;
        font-size: 13px;
        font-weight: 300;
        color: #222 !important;
        text-transform: uppercase;

        .ipad-land-less({
            font-size: 11px;
            // padding: 20px 20px;
        });

        .ipad-less({
            padding: 10px 0;
            text-align: center;
        });

        &:hover{
            color: lighten(#000, 10%);
        }
    }

    .nav.navbar-nav{
        display: inline-block;
        margin: 0 auto;
        float: none;
        width: auto;

        @media screen and (max-width: 1023px) {
            width: 100%;
        }
    }

    ul{

        .ipad-less({
            position: relative;

            &:first-child{

                // &:after{
                //     content: '';
                //     position: absolute;
                //     bottom: 0;
                //     left: 50%;
                //     transform: translateX(-50%);
                //     height: 1px;
                //     width: 50px;
                //     background-color: #000;
                // }
            }

            &:nth-child(2){
                margin-bottom: 30px;
            }

        });
    }
}

.navbar-brand a{
    display: block;
    padding: 45px 0;
    z-index: 4;

    .ipad-land-less({
        padding: 25px 5px;
    });

    .ipad-less({
        padding: 0 5px;
        max-width: 150px;
        margin: 0 auto;
    });
}

.navbar-brand{

    .ipad-less({
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: 0 !important;
    });
}

.active-border{
    position: relative;

    &:after{
        content: '';
        position: absolute;
        width: 60px;
        height: 1px;
        background-color: #b1b1b1;
        bottom: 6px;
        right: 0;
    }
}
