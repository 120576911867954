.body-white{
    background-color: #fff;
}

.content .profile-page{

    a{
        color: #000;

        i{
            margin-left: 10px;
        }
    }
}

.add-to-cart{

    &_normalize{
        margin-bottom: 0;
    }
}
