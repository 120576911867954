html, body {
    min-height: 100%;
}

body.catalog-page{
    background-color: transparent;
}

.catalog__item {
    position: relative
}

.catalog__item__description {
    // text-align: center;
    margin-top: 10px;
    bottom: 0;
    // height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // flex-direction: column;
    color: #232323;
    font-weight: 300;
}

.catalog__item__description a {
    color: #232323;
    text-decoration: none;
}

.catalog__item__hr {
    background-color: #232323;
    height: 1px;
    width: 60px;
    margin: 0 auto 10px auto;
}

.catalog__top-menu {
    list-style: none;
    padding: 0;
    height: 100px;
    margin: 0;
    position: relative;
    z-index: 2;
}
.catalog__top-menu > li {
    display: inline-block;
}
.catalog__top-menu > li > a {
    color: black;
    padding: 60px 20px;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px solid transparent;
    font-size: 15px;

    @media screen and (max-width: 1320px) {
        padding: 30px 10px;
        font-size: 12px;
    }

    @media screen and (max-width: 1280px){
        padding: 20px 5px;
        font-size: 12px;
    }

    &:hover{
        border: 1px solid black;
    }
}

.card-catalog{
    margin-bottom: 0px;
    border: 0.5px solid rgb(0, 21, 44);
}

.card-catalog-img-wrapper {
    position: relative;
}

.catalog-listing{

    .ipad-less({
        padding: 0 15px !important;
        position: relative;
    });
}



.catalog-btn-radio {
  border: 1px solid #666;
}
.catalog-btn-radio:hover,
.catalog-btn-radio:focus,
.catalog-btn-radio:active,
.catalog-btn-radio.active,
input:checked + .catalog-btn-radio {
  border-color: #333333;
  background: #333333;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.catalog-btn-img {
  padding: 0;
  border: 2px solid transparent;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: 5px;
}
.catalog-btn-img:hover,
.catalog-btn-img:focus,
.catalog-btn-img:active,
.catalog-btn-img.active,
input:checked + .catalog-btn-img {
  border-color: #333333;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.card-catalog-like {
  position: absolute;
  top: 0;
  left: 0;
  color: #999999;
  line-height: 1em;
}
.card-catalog-like:hover,
.card-catalog-like:focus,
.card-catalog-like:active,
.card-catalog-like.active {
  text-decoration: none;
}
.card-catalog-like:hover .icon-active,
.card-catalog-like:focus .icon-active,
.card-catalog-like:active .icon-active,
.card-catalog-like.active .icon-active {
  opacity: 1;
}
.icon-active {
  position: absolute;
  opacity: 0;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.instagram-gallery{
    background-color: #ECECE9;
    padding-bottom: 30px;

    &__title{
        font-weight: normal;
        font-size: 18px;
        margin: 15px 0 0px;
    }

    &__list{
        margin-top: 10px;
        margin-bottom: 60px;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-column-gap: 35px;
        grid-row-gap: 25px;

        @media (max-width: @screen-sm) {
            grid-template-columns: repeat(2,1fr);
        }
    }

    &__item{
        background-color: #FFF;
        padding: 6px;
        box-shadow: 0 3px 6px rgba(0,0,0,.25);

        .instagram-gallery-item__description{
            margin-top: 10px;
            padding: 0 5px;
        }
    }
}


.panel-heading, .panel-body{
    padding: 10px 0px;
}

.pay-button{
    border: 1px solid #131219 !important;
    padding: 14px 10px 11px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    span{
        display: block;
    }

    img{
        display: block;
        width: auto;
        margin-left: 10px;
        height: 25px;
    }
}

.apple-pay-button{
    width: 100%;
    height: 48px;
}
