.side-menu-btn-fix{
    a{
        width: auto;
        height: auto;
        border-radius: 0;
        background-color: transparent;

        &:hover{
            background-color: transparent;
        }
    }
}

.side-menu-btn-fix{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 2001;
}

.side-nav-button{
    width: 64px;
    height: 64px;
    border: 1px solid #fff;
    border-right: none;
    background-color: transparent;
    overflow: hidden;
    position: relative;

    &:after,
    &:before{
        content: '';
        position: absolute;
    }

    &:after{
        width: 63px;
        height: 63px;
        left: 0;
        top: 0;
        border: 1px solid #fff;
        border-right: none;
        border-top: none;
        transform: translateX(21%) rotate(45deg);
    }

    &:before{
        width: 63px;
        height: 1px;
        background-color: #fff;
        top: 50%;
        left: 0;
    }

    @media screen and (max-width: 768px){
        width: 52px;
        height: 52px;

        &:after{
            width: 52px;
            height: 52px;
        }

        &:before{
            width: 52px;
        }
    }

    &_close{
        transform: rotate(180deg);
        border-right: 1px solid #fff;

        // @media screen and (max-width: 768px){
        //     width: 52px;
        //     height: 52px;
        //     transform: rotate(180deg);
        //
        //     &:after{
        //         width: 52px;
        //         height: 52px;
        //     }
        //
        //     &:before{
        //         width: 52px;
        //     }
        // }
    }
}

.side-menu .menu-close.toggle-menu_mobile{
    display: none;
    transition: all 0.5s ease-out;
}
.push-body-toleft .side-menu .menu-close.toggle-menu_mobile{

    @media screen and (max-width: 768px){
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -51px;
        transition: all 0.5s ease-out;
    }
}

.black-side-menu-btn .side-nav-button{
    border: 1px solid #000;
    border-right: none;

    &:after{
        border: 1px solid #000;
        border-right: none;
        border-top: none;
    }

    &:before{
        background-color: #000;
    }

    &_close{
        border: 1px solid #fff;

        &:after{
            border: 1px solid #fff;
            border-right: none;
            border-top: none;
        }

        &:before{
            background-color: #fff;
        }
    }
}

.side-menu{
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    background-color: #fff;

    @media screen and (max-width: 768px){
        overflow: visible;
    }

    .menu-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        @media screen and (max-width: 768px){
            // display: block;
            overflow-y: scroll;
            min-height: 0;
            flex: 1 1 auto;
        }
    }

    &:after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background-color: #f7f7f9;
    }

    .menu-close{
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
    }

    &__close-btn{
        display: -webkit-flex;
        display: -webkit-box;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
        background-color: #000;
        z-index: 1;
        position: relative;

        .desktop-lg-less({
            height: 150px;
        });


        @media screen and (max-width: 768px){
            // display: none;
        }
    }

    &__nav{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;

        .lang-chooser{
            z-index: 2;

            ul{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                border: 1px solid #000;

                .phone({
                    margin-bottom: 0;
                });

                li{
                    padding: 10px;
                    margin-bottom: 0;

                    &:after{
                        height: 0;
                    }
                }

                .lang.active {
                    a {
                        color: goldenrod;
                    }
                }

                a{
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    color: #000;
                    font-weight: 100;
                    text-transform: uppercase;

                    &:hover{
                        color: lighten(#000, 30%);
                        text-decoration: none;
                    }
                }
            }
        }

        li{
            text-align: center;

            &:after{
                content: '';
                position: absolute;
                width: 40px;
                height: 1px;
                background-color: #000;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;

                .desktop-lg-less({
                    height: 0;
                });
            }

            &:last-child:after{
                height: 0;
            }
        }
    }

    &__nav-link{
        color: #000;
        font-size: 15px;
        text-transform: uppercase;
        padding: 25px 0 !important;
        z-index: 1;
        position: relative;

        .desktop-lg-less({
            padding: 10px 0 !important;
            font-size: 13px;
        });

        .phone({
            padding: 5px 0 !important;
        });

        &:hover{
            background-color: transparent !important;
            color: lighten(#000, 30%) !important;
        }
    }
}

@media screen and (max-width: 768px) {
    .side-menu__close-btn{
        height: 100px;
    }
}
