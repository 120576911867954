body{
    background-color: #FFF;
    font-family: @font-base;
}
b, strong {
    font-weight: 500;
}

.main-title{
    position: relative;
    font-weight: 300;
    margin-top: 0;
    padding-bottom: 15px;
    margin-bottom: 10px;

    &:after{
        content: '';
        position: absolute;
        width: 60px;
        height: 1px;
        background-color: #b1b1b1;
        bottom: 2px;
        right: 50%;
        left: 50%;
        margin-left: -30px;
    }
}

.logo{

}
.file a img{
    display: none;
}
iframe{
    width: 100%;
    height: 100%;
}

.index-page #fp-nav{
    display: none !important;
}

@media screen and (max-width:@screen-sm-min) {
    .logo img{
        max-width: 70%;
    }
}

video {
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // min-width: 100%;
    // min-height: 100%;
    width: 100%;
    height: auto;
    // z-index: -100;
    // transform: translateX(-50%) translateY(-50%);
    // // background: url('//demosthenes.info/assets/images/polina.jpg') no-repeat;
    // background-size: cover;
    // transition: 1s opacity;
    // background-color: #000;
}

.video-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
}

.video-logo{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#bgvid{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.controls-wrapper{
    display: none !important;
}

.contact-box{
    min-height: 240px;

    h3{
        text-transform: none;
        letter-spacing: normal;
    }
}

p{
    font-weight: 300;
}

.plugin-blocks-element_without-img .plugin-blocks-element__info{
    height: 500px;
    display: table-cell;
    vertical-align: middle;
}

img{
	width: 100%;
	height: auto;
	max-width: 100%;
}
.index-list{
    padding-top: 0px !important;
}

.modal-item-card{
  min-height: 800px;
}

.modal-item-card .close{
    // right: -10px;
    outline: none;
}

.cms-plugin .plugin-blocks-element_without-img{
	width: 355px;
}

#js-register:hover, #js-enter:hover{
    color: #fff;
    background-color: #000;
}

.news-list{
    font-size: 13px;
    line-height: 24px;
    font-weight: 300;

    h2{
        font-size: 13px;
        font-weight: 400;

        a{
            color: #000;
        }
    }
}
h1, .h1, h2, .h2, h3, .h3{
    font-family: @font-playfair;
}
